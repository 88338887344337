import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import profilbild from '../assets/images/profilbild.jpg';
import config from '../../config';

const content = require("../assets/texts/intro.json");

const IndexPage = () => (
  <Layout fullMenu>
    <section id="banner">
      {/* <div className="inner">
        <img src={logo_small} alt="" />
      </div> */}
      <div className="inner accheroes">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        {/* <p>{config.subHeading2}</p> */}
        <ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Erfahre mehr
              </a>
            </Scroll>
            <Scroll type="id" element="one">
              <a href="/#" className="more"/>
            </Scroll>
          </li>

        </ul>
      </div>
    </section>
    <section id="one" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={profilbild} alt="" />
        </div>
        <div className="content">
          <h2>{content.profile.title}</h2>
          <p>{content.profile.meaning}</p>
          <p>{content.profile.program}</p>
        </div>
      </section>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>{content.profile.booking}</h2>
          <p>{content.profile.getInContact}</p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="mailto:booking@acoustic-heroes.com" className="button fit">
              e-Mail
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
