module.exports = {
  siteTitle: 'acoustic-heroes.com', // <title>
  manifestName: 'acousticHeroes',
  manifestShortName: 'acheroes', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#3e225c',
  manifestThemeColor: '#3e225c',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/nlogo_100x100.png',
  //pathPrefix: `/gatsby-starter-spectral/`, --> Not used since we're ona root page // This path is subpath of your hosting https://domain/portfolio
  heading: 'acoustic heroes',
  subHeading: 'aus Rottweil',
  subHeading2: '',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagran',
      url: 'https://instagram.com/acoustic_heroes',
    },
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://de-de.facebook.com/Acoustic.Heroes.Rottweil/',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:booking@acoustic-heroes.com',
    },
  ],
};
